
import Vue from 'vue';
import { ClientInvitation, ClientInvitationInfoResponse, SnackbarInfo, ClientInvitationRegistration, Person } from "@/types/responses"
import { LinkOnboardingKind } from '@/types/enums';

export default Vue.extend({
	name: "ApproveRegistrationDialog",
	props: {
		showApprove: {
			type: Boolean,
		}
	},
    async mounted() {
        if(!this.hasFetchedInfo()){
			await this.refreshClientInvitationInfo();
		} else {
            this.loadingInfo = false;
        }

        this.loading = false
	},
	data(): {
		snackBar: boolean
		snackBarText: string
		loading: boolean
        loadingInfo: boolean
        loadingAction: boolean
		timeLeft: number

        checkbox: boolean

        deleteDialog: boolean
	} {
		return {
			snackBar: false,
			snackBarText: "",
			loading: false,
            loadingInfo: false,
            loadingAction: false,
			timeLeft: 2,

            checkbox: false,

            deleteDialog: false
		}
	},
    watch: {
		clientInvitationInfo: {
            deep: true,
            handler(){
                console.log("updated clientInvitationInfo()")

                // Error handler: wrong data loaded
                if(
                    this.loadingInfo && this.clientInvitationInfo && this.clientInvitation &&
                    this.clientInvitationInfo.jobId !== 
                    this.clientInvitation!.id
                ){
                    this.refreshClientInvitationInfo()
                }
            }
        }
	},
    computed: {
        clientInvitation(): ClientInvitation {
            return this.$store.state.context!.selectedClientInvitation 
        },
		clientInvitationInfo(): ClientInvitationInfoResponse | null {
            if(this.loading && !this.loadingInfo) return this.$store.state.context!.selectedClientInvitationInfo
            return null
        },
        clientRegistration(): ClientInvitationRegistration {
            return this.$store.state.context!.selectedRegistration
        },
		usersComplete(): Person[] {
			return this.$store.state.context.persons
		},
        personsComplete(): Array<{ text: string, value: null|string}> {
			const arr: Array<{ text: string, value: null|string}> = []
			arr.push({ text: "Select a user ..", value: null})
			// eslint-disable-next-line
			const persons = this.$vStore.state.context.persons
			.sort(function(a, b) {
				const obj1 = a['id'].toUpperCase()
				const obj2 = b['id'].toUpperCase()
				if (obj1 < obj2) return -1
				if (obj1 > obj2) return 1
				return 0
			})
			.map(person => {
				return { text: person.displayName, value: person.id}
			})
			const selectList = [...arr,...persons]
			return selectList
		},
		showOrganizationInfo(): boolean {
			const name = this.clientRegistration.clientOrganizationName;
			return name != undefined && name != null && name != '';
		}
	},
	methods: {
        hasFetchedInfo(): boolean {
			if(this.$store.state.context!.selectedClientInvitationInfo && this.$store.state.context!.selectedClientInvitation){
                const selectedClientInvitationInfo: ClientInvitationInfoResponse = this.$store.state.context!.selectedClientInvitationInfo;
                const selectedClientInvitation: ClientInvitation = this.$store.state.context!.selectedClientInvitation;
                
                if(
                    selectedClientInvitationInfo &&
                    selectedClientInvitationInfo!.jobId == 
                    selectedClientInvitation!.id
                ) return true
                return false;
			} else {
				return false;
			}
		},

        disableCreateButton(): boolean {
			if(this.loadingAction) return true

			return false
		},
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
        async refreshClientInvitationInfo(): Promise<void> {
            if(this.loadingInfo) return
			this.loadingInfo = true
            this.loading = true

            try {
                /* Create selectedClientInvitation */
				//const clientInvitation: ClientInvitation = {
				//	id: hit.jobId,
				//name: hit.displayName
				//}

				/* Store selection */
            	//await this.$vStore.dispatch("context/setClientInvitation", clientInvitation)

                await this.$vStore.dispatch("context/getClientInvitationInfo")
                this.loadingInfo = false;
            } catch(e){
                console.warn("Error: getClientInvitationInfo()")
            }
		},
        
		cancel(): void {
			if(!this.loadingAction){
				this.$emit("close", null)
			}
		},

        async deleteRegistration() {
            this.deleteDialog = false;

            this.loadingAction = true
            let snackbarInfo: SnackbarInfo;
			try {
			    await this.$vStore.dispatch("context/rejectClientRegistration")
				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully rejected registration.",
					e: null
				}

                this.loadingAction = false
			    this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 1)

			    this.cancel()
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not reject registration.",
					e: e
				}
                this.loadingAction = false
			    this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 1)
			}
        },

        async approveRegistration() {
            this.loadingAction = true
            let snackbarInfo: SnackbarInfo;
			try {
			    await this.$vStore.dispatch("context/approveClientRegistration")
				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully approved registration.",
					e: null
				}

                this.loadingAction = false
			    this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 2)

			    this.cancel()
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not approve registration.",
					e: e
				}
                this.loadingAction = false
			    this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 2)
			}
        },

	},
})
